import { IExpertAvailabilitiesActionTimeSlot } from '../../../shared/models/expert-availability-actions.models';

export enum ExpertUpdateType {
  ConfirmAvailability = 'ConfirmAvailability',
  RequestDifferentTime = 'RequestDifferentTime',
  SeenByClient = 'SeenByClient',
  UpdateClientNotes = 'UpdateClientNotes',
  ExpertOnHold = 'ExpertOnHold',
  ExpertRejection = 'ExpertRejection',
  FavouriteExpert = 'FavouriteExpert',
  ExpertApproval = 'ExpertApproval',
  ExpertSchedule = 'ExpertSchedule',
  RequestBio = 'RequestBio',
}

export enum ExpertReadyToAcceptCodeMap {
  Hidden = 'hidden',
  ClientRejected = 'client-rejected',
  Accepted = 'accepted',
  Ready = 'ready-to-accept',
}

export interface IConfirmAvailability {
  type: ExpertUpdateType.ConfirmAvailability;
  expertId: string;
  payload: {
    callTime: { start: string; end: string; expertActionId?: string };
    clientTimezone: string;
  };
}

export interface IRequestDifferentTime {
  type: ExpertUpdateType.RequestDifferentTime;
  expertId: string;
  payload: {
    clientTimezone: string;
    newTime: string;
    timeSlots: IExpertAvailabilitiesActionTimeSlot[];
  };
}

export interface ISeenByClient {
  type: ExpertUpdateType.SeenByClient;
  expertId: string;
}

export interface IUpdateClientNotes {
  type: ExpertUpdateType.UpdateClientNotes;
  expertId: string;
  payload: { clientNotes: string };
}

export interface IExpertOnHold {
  type: ExpertUpdateType.ExpertOnHold;
  expertId: string;
  payload: { onHold: boolean; onHoldReason?: string };
}

export interface IExpertRejection {
  type: ExpertUpdateType.ExpertRejection;
  expertId: string;
  payload: { clientRejected: boolean; rejectionReason?: string };
}

export interface IFavouriteExpert {
  type: ExpertUpdateType.FavouriteExpert;
  expertId: string;
  payload: { favourited: boolean };
}

export interface IExpertApproval {
  type: ExpertUpdateType.ExpertApproval;
  expertId: string;
  payload: { clientApproved: boolean };
}

export interface IExpertSchedule {
  type: ExpertUpdateType.ExpertSchedule;
  expertId: string;
  payload: {
    callTime: { start: string; end: string; expertActionId?: string };
    primaryRecipient: string;
    clientTimezone: string;
    hosts: string[];
    guests: string[];
  };
}

export interface IExpertRequestBio {
  type: ExpertUpdateType.RequestBio;
  expertId: string;
}

export type ExpertUpdate =
  | IConfirmAvailability
  | IRequestDifferentTime
  | ISeenByClient
  | IUpdateClientNotes
  | IExpertOnHold
  | IExpertRejection
  | IFavouriteExpert
  | IExpertApproval
  | IExpertSchedule
  | IExpertRequestBio;

export interface IExpertReadyToAcceptReturn {
  isReady: boolean;
  code: ExpertReadyToAcceptCodeMap;
}
