export const environment = {
  production: false,
  base: 'https://dev.portal.techspert.com',
  connectApiBaseUrl: 'https://dev.api.connect.techspert.io',
  filestoreEndpoint: 'https://dev.api.connect.techspert.io/filestore',
  screenerEndpoint: 'https://dev.screener.techspert.com',
  gaMeasurementId: 'G-LWE3MB2DCQ',
  auth0: {
    domain: 'dev.id.techspert.com',
    clientId: 'wtZNO9tTYWetl1Jn6qsLp1nEoMJlp2qB',
    authorizationParams: {
      connection: 'email',
      audience: 'https://dev.api.techspert.io',
    },
  },
  growthBookClientKey: 'sdk-XRBwI4muyGXFhdpx',
};
