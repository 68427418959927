import {
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { CognitoAuthService, IUser } from '@techspert-io/auth';
import * as moment from 'moment-timezone';

export interface IRequestConferenceDialogData {
  contacts: IUser[];
  callTime: string;
  expertTimezone: string;
  hostedCall: boolean;
}

export interface IRequestConferenceDialogResult {
  primaryRecipient: string;
  hosts: string[];
  guests: string[];
  clientTimezone: string;
}

@Component({
  selector: 'app-request-conference-dialog',
  templateUrl: './request-conference-dialog.component.html',
  styleUrls: ['./request-conference-dialog.component.scss'],
})
export class RequestConferenceDialogComponent implements OnInit {
  get expertCallTime(): string {
    return moment(this.data.callTime)
      .tz(this.data.expertTimezone)
      .format('ddd MMM DD YYYY, LT');
  }

  get clientCallTime(): string {
    return moment(this.data.callTime)
      .tz(
        this.clientContacts.find(
          (d) => d.email === this.conferenceForm.value.primaryRecipient
        )?.timezone.name ||
          this.currentUser.timezone.name ||
          'UTC'
      )
      .format('ddd MMM DD YYYY, LT');
  }

  get currentUser(): IUser {
    return this.authService.loggedInUser;
  }

  get hostsCtrls(): FormArray<FormControl<string>> {
    return this.conferenceForm.controls.hosts;
  }

  get guestsCtrls(): FormArray<FormControl<string>> {
    return this.conferenceForm.controls.guests;
  }

  get clientContacts(): IUser[] {
    return this.data.contacts;
  }

  @ViewChildren(MatInput) recipientInputs: QueryList<MatInput>;

  conferenceForm = new FormGroup({
    primaryRecipient: new FormControl<string>(null, [
      Validators.required,
      Validators.email,
    ]),
    hosts: new FormArray<FormControl<string>>([]),
    guests: new FormArray<FormControl<string>>([]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IRequestConferenceDialogData,
    private dialogRef: MatDialogRef<
      RequestConferenceDialogComponent,
      IRequestConferenceDialogResult
    >,
    private authService: CognitoAuthService
  ) {}

  ngOnInit(): void {
    this.setupClientContacts(this.clientContacts);
  }

  addRecipient(
    type: 'host' | 'guest',
    email: string = null,
    focus = false
  ): void {
    if (type === 'host') {
      this.hostsCtrls.push(
        new FormControl(email, [Validators.required, Validators.email])
      );
    } else {
      this.guestsCtrls.push(
        new FormControl(email, [Validators.required, Validators.email])
      );
    }

    if (focus) {
      setTimeout(() => this.recipientInputs.last.focus(), 0);
    }
  }

  removeRecipient(type: 'host' | 'guest', idx: number): void {
    if (type === 'host') {
      this.hostsCtrls.removeAt(idx);
    } else {
      this.guestsCtrls.removeAt(idx);
    }
  }

  recipientKeyDown(type: 'host' | 'guest', event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.addRecipient(type);
      setTimeout(() => this.recipientInputs.last.focus(), 0);
    }
  }

  confirm(): void {
    if (this.conferenceForm.valid) {
      const primaryRecipient = this.conferenceForm.value.primaryRecipient;

      this.dialogRef.close({
        primaryRecipient: primaryRecipient,
        hosts: this.conferenceForm.value.hosts,
        guests: this.conferenceForm.value.guests,
        clientTimezone:
          this.clientContacts.find((d) => d.email === primaryRecipient)
            ?.timezone.name || this.currentUser.timezone.name,
      });
    }
  }

  private setupClientContacts(contacts: IUser[]): void {
    const contactEmails = contacts.map((c) => c.email);
    const primaryContact = contactEmails.find(
      (e) => e === this.currentUser.email
    );

    this.conferenceForm.setValue({
      primaryRecipient: primaryContact || null,
      hosts: [],
      guests: [],
    });
    contactEmails
      .sort((a, b) => a.localeCompare(b))
      .filter((email) => email !== primaryContact)
      .forEach((email) => this.addRecipient('host', email));
  }
}
